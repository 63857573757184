<template>
  <v-container class="Product_bar">
    <v-card class="d-flex mb-6" color="lighten-2" flat tile>
      <v-card class="pa-2 mr-auto" elevation="0" tile>
        <h4 class="System_admin_text_Product ml-2">USER CONTACT PAGE</h4>
      </v-card>
      <v-card class="pa-2" elevation="0" tile>
        <b>User ID: {{ currentUserID }}</b>
      </v-card>
    </v-card>
    <v-row no-gutters class="ml-7">
      <v-col cols="3">
        <v-select
          v-model="currentUserID"
          :items="userList"
          label="Select User"
          item-text="text"
          item-value="id"
          style="text-align: center"
          height="20"
          class="mt-4"
          outlined
          @change="getLimitUserData"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <b-button-group class="mt-5 ml-2">
          <b-button @click="saveFormData" class="save-button">Save</b-button>
          <b-button @click="goBack" class="back-button">Back</b-button>
        </b-button-group>
      </v-col>
      <v-col cols="3" class="small-lblhead mt-6">
        <input
          type="file"
          ref="file"
          @change="updatePreview"
          class="file-input"
          id="file-input"
        />
        <label for="file-input">
          <i class="fa fa-folder"></i>
          &nbsp;Select user photo...
        </label>
        <b-button
          v-if="file"
          class="small-lblhead ml-2 field"
          @click="saveUserImage"
        >
          <i class="fa fa-upload"></i>
          Upload File
        </b-button>
      </v-col>
    </v-row>
    <div>
      <v-container>
        <v-form v-model="valid">
          <v-row>
            <v-col cols="6" id="Right Side Columns">
              <v-col cols="12">
                <template id="Primary Image File">
                  <div>
                    <label class="small-lblhead"><b>USER IMAGE FILE</b></label>
                    <b-form-input
                      class="small-bold-input"
                      v-model="userData.user_photo_file_name"
                      placeholder="Image File"
                    >
                    </b-form-input>
                  </div>
                </template>
              </v-col>

              <v-col cols="12">
                <form @submit.prevent="sendFile" enctype="multipart/form-data">
                  <v-row>
                    <div class="user-profile ml-1">
                      <v-img
                        v-if="!!profileImageUrl"
                        :src="profileImageUrl"
                        contain
                        width="300"
                        class="preview-image"
                        @click="downloadFile(userData.file, userData.filename)"
                        outlined
                      >
                      </v-img>
                      <span class="caption" v-else>No Image Uploaded</span>
                    </div>
                  </v-row>
                </form>
              </v-col>

              <v-col cols="12"> </v-col>

              <v-col cols="12"> </v-col>
            </v-col>

            <v-col cols="6">
              <template id="CONTACT NAME">
                <div>
                  <BR></BR>
                  <label class="small-lblhead"><b>CONTACT NAME</b></label>
                  <b-form-input
                    class="small-bold-input"
                    v-model="userData.full_name"
                    placeholder="Full Name"
                    :rules="[(v) => !!v || 'This field is required']"
                  >
                  </b-form-input>
                </div>
              </template>
              <v-row>
                <v-col cols="5">
                  <template id="City">
                    <div>
                      <label class="small-lblhead"><b>CITY</b></label>
                      <b-form-select
                        class="small-bold-text"
                        v-model="userData.city_id"
                        placeholder="City"
                        :options="list_city"
                        value-field="id"
                        text-field="text"
                        disabled-field="notEnabled"
                        :rules="[(v) => !!v || 'This field is required']"
                      >
                      </b-form-select>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="State">
                    <div>
                      <label class="small-lblhead"><b>STATE</b></label>
                      <b-form-select
                        class="small-bold-text"
                        v-model="userData.state_id"
                        placeholder="State"
                        :options="list_states"
                        value-field="id"
                        text-field="text"
                        :rules="[(v) => !!v || 'This field is required']"
                      >
                      </b-form-select>
                    </div>
                  </template>
                </v-col>
                <v-col cols="4">
                  <template id="ZIPCODE">
                    <div>
                      <label class="small-lblhead"><b>ZIPCODE</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.zip"
                        placeholder="Zip"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="5">
                  <template id="Primay Phone">
                    <div>
                      <label class="small-lblhead"><b>MAIN PHONE</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.phone"
                        placeholder="Main Phone"
                        maxlength="15"
                        v-mask="mask.phone"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="4">
                  <template id="TEXT">
                    <div>
                      <label class="small-lblhead"><b>TEXT</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.text_no"
                        placeholder="Text"
                        maxlength="15"
                        v-mask="mask.phone"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="3">
                  <template id="UID">
                    <div>
                      <label class="small-lblhead"><b>UID</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.uid"
                        placeholder="UID"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead">
                <v-col cols="7">
                  <template id="COMPANY">
                    <div>
                      <label class="small-lblhead"><b>COMPANY</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.company"
                        placeholder="Company"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="5">
                  <template id="EMAIL">
                    <div>
                      <label class="small-lblhead"><b>EMAIL</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.email"
                        placeholder="Email"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>

              <v-row class="small-lblhead"> </v-row>
              <v-row class="small-lblhead">
                <v-col cols="6">
                  <template id="FACEBOOK">
                    <div>
                      <label class="small-lblhead"><b>FACEBOOK</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.facebook"
                        placeholder="Facebook"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="INSTAGRAM">
                    <div>
                      <label class="small-lblhead"><b>INSTAGRAM</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.instagram"
                        placeholder="Instagram"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="WHATSAPP">
                    <div>
                      <label class="small-lblhead"><b>WHATSAPP</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.whatsapp"
                        placeholder="WhatsApp"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
                <v-col cols="6">
                  <template id="LINKEDIN">
                    <div>
                      <label class="small-lblhead"><b>LINKED IN</b></label>
                      <b-form-input
                        class="small-bold-input"
                        v-model="userData.linkedin"
                        placeholder="LinkedIN"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
    <v-dialog v-model="dialogEdit" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>
            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>{{ userData.id ? "Update Item" : "Add Item" }}</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask,
  },

  created() {
    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));
    CommonServices.Detail(
      localStorage.getItem("userid"),
      this.componentURL_base
    )
      .then((res) => {
        console.log("Contact Detail Response: ", res);
        this.userData = res;
        this.profileImageUrl = this.userData.user_photo_file;
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Cities")
      .then((res) => {
        console.log("Cities", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_city.push({ id: code.id, text: code.city })
        );
        console.log("list_city: ", this.list_city);
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("States")
      .then((res) => {
        console.log("States", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_states.push({ id: code.id, text: code.state })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Users")
      .then((res) => {
        res.results.map((user) =>
          this.userList.push({
            id: user.id,
            text: user.first_name + " " + user.last_name,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `Users/?id=${this.$route.params.id}`,
      componentURL_base: `Users`,
      userList: [],
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogEdit: false,
      dialogSelectUser: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      currentUserID: parseInt(localStorage.getItem("userid")),
      file: "",
      profileImageUrl: "",
      items: [],
      list_city: [],
      list_states: [],
      userData: {
        id: "",
        uid: "",
        first_name: "",
        last_name: "",
        full_name: "",
        city_id: null,
        state_id: null,
        zip: null,
        company: "",
        phone: null,
        text_no: null,
        facebook: null,
        email: null,
        instagram: null,
        whatsapp: null,
        linkedin: null,
        verify_method: null,
        django_pwd: "",
        user_type_id: "",
        user_type: "",
        active: "",
        admin: "",
        user_photo_file: "",
        timestamp: null,
        user_photo_file_name: "",
      },
      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "ContactWorksheet",
        action: "",
        deal_id: "",
        json: "",
      },
      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    getLimitUserData() {
      if (!isNaN(Number(this.currentUserID))) {
        CommonServices.Detail(
          parseInt(this.currentUserID),
          this.componentURL_base
        )
          .then((res) => {
            this.userData = res;
            this.profileImageUrl = this.userData.user_photo_file;
            this.$refs.form.reset();
          })
          .catch((err) => {
            console.error(`Error fetching user details ${err}`);
          });
      }
    },

    openEdit() {
      this.userData.length = 0;
      this.dialogEdit = true;
    },

    downloadFile(url, filename) {
      console.log("URL: ", url, "Filename: ", filename);
      CommonServices.downloadFile(url, filename);
    },

    displayFile(item) {
      console.log("Title: ", item.title);
      this.userData.file_name = item.title;
    },

    async saveUserImage() {
      //This will upload only the user's photo to the UserPhotos table
      try {
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("upload_file_name", this.file.name);
        formData.append("user_id", parseInt(this.currentUserID));
        await CommonServices.postData("/UserPhotos", formData).then((res) => {
          this.profileImageUrl = res.file;
          if (
            parseInt(this.currentUserID) ===
            parseInt(localStorage.getItem("userid"))
          )
            localStorage.setItem("user_photo_file", this.profileImageUrl);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Photo successfully updated";
          this.dialog = false;
          this.logActivity("Uploaded Photo", "", JSON.stringify(this.userData));
        });
      } catch (error) {
        this.snackbar = true;
        this.snackColor = "error";
        try {
          Object.keys(error.response.data).forEach((key) => {
            this.snackText = error.response.data[key][0];
          });
        } catch (e) {
          this.snackText = "Something went wrong";
        }
      }
    },

    updatePreview(e) {
      console.log("e", e);
      var reader,
        files = e.target.files;

      if (files.length === 0) {
        console.log("Empty");
      }

      reader = new FileReader();

      reader.onload = (e) => {
        this.profileImageUrl = e.target.result;
      };

      reader.readAsDataURL(files[0]);

      this.file = this.$refs.file.files[0];
      console.log("File: ", this.file);
      this.userData.file_name = this.file.name;
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Contact"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Contact.xlsx");
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if (this.userData.id) {
        CommonServices.updateData(this.componentURL_base, this.userData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.userData.deal_id,
              JSON.stringify(this.userData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        CommonServices.addRecord(this.componentURL, this.userData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Error while filtering " + this.searchText;
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL_base, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
          this.logActivity(
            "Deleted Record",
            this.editedItem.deal_id,
            JSON.stringify(this.editedItem)
          );
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = error;
          console.log(error);
        });
      this.closeDelete();
    },

    deleteItemSilently() {
      CommonServices.deleteRecord(this.componentURL_base, this.userData.id)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.getData();
        })
        .catch((error) => {
          console.log("Delete Error: ", error);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.userData = Object.assign({}, this.userData);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.$refs.form.reset();
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.userData = Object.assign({}, this.userData);
        this.editedIndex = -1;
      });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
button {
  color: white;
  border-radius: 2px;
  cursor: pointer;
  font-size: 13px;
}

.offers-button {
  background-color: rgba(145, 9, 31, 255);
  margin-left: 0px;
  margin-right: 8px;
  height: 36px;
  width: 125px;
}

.save-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-right: 8px;
}

.tasks-button {
  background-color: rgba(160, 152, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.uploads-button {
  background-color: rgba(81, 109, 131, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blue-button {
  background-color: rgb(13, 13, 68);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.add-button {
  background-color: rgba(194, 88, 48, 255);
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.back-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.worksheets-button {
  background-color: rgba(0, 0, 0, 255);
  border: none;
  border-radius: 8px;
  border-radius: 1px;
  width: 130px;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.showall-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  color: black;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.completed-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.incomplete-button {
  background-color: rgba(81, 109, 131, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}
.purple-button {
  background-color: rgba(124, 96, 121, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.orange-button {
  background-color: rgba(194, 86, 47, 255);
  border: none;
  margin-left: 0px;
  margin-right: 0px;
  height: 36px;
  width: 125px;
}

.biege-button {
  background-color: rgba(227, 223, 212, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

.blah-button {
  background-color: rgba(160, 152, 131, 255);
  border: none;
  height: 36px;
  width: 125px;
  margin-left: 8px;
  margin-right: 8px;
}

input#file-input {
  display: none;
}

.small-lblhead {
  font-style: normal;
  font-weight: 350;
  font-size: 10px;
  line-height: 0pt;
}

.small-bold-input {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 0pt;
  background-color: #03233f13;
}

.small-bold-text {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  background-color: #03233f13;
}

input#file-input + label {
  background-color: #0157a0;
  padding: 5px;
  color: #fff;
  border: 2px solid #85bdee;
  border-radius: 9px;
  line-height: 10pt;
}

input#file-input + label:hover {
  background-color: #20e0a5;
  border-color: #fff;
  cursor: pointer;
}
.err-message {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: red;
  line-height: 0pt;
}

.small-lbl {
  font-style: italic;
  font-weight: 800;
  font-size: 11px;
  line-height: 0pt;
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}

.flex-end {
  justify-content: flex-end;
}

.header-add-Product {
  text-align: right;
  margin-left: 0%;
}

.header-add-Product button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-Product:focus,
.header-add-Product button:focus {
  outline: none !important;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

@media only screen and (max-width: 768px) {
  .header-add-Product {
    margin-left: 0 !important;
  }
}
</style>
